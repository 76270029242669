<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_provide"
        data-wizard-state="step-first"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div class="wizard-step my-1" data-wizard-type="step" :data-wizard-state="step.state" v-for="step in steps" :key="step.title">
              <div class="wizard-wrapper">
                <div class="wizard-label">
                  <h3 class="wizard-title mb-0">
                    {{ step.title }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form">
                
                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    Olanağı tanımlayın
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Müşterilere sunmak istediğiniz olanağı Türkçe ve İngilizce olarak anlatın.
                  </p>

                   <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="!validation.step1">
                    <div class="alert-text text-body d-flex align-items-center">
                      <span class="svg-icon svg-icon-warning pr-2">
                        <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
                      </span>
                      <span class="font-weight-bold">Devam edebilmek için lütfen olanağa Türkçe ve İngilizce isim verin.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                      Olanak
                      <Flag class="ml-2" code="TR" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                    </label>
                    <input type="text" class="form-control form-control-lg" placeholder="Olanağı isimlendirin" v-model="title.tr"/>
                  </div>

                  <div class="form-group">
                    <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                      Açıklama (İsteğe Bağlı)
                      <Flag class="ml-2" code="TR" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                    </label>
                    <textarea
                      class="form-control mb-2"
                      rows="3"
                      placeholder="Açıklamayı buraya yazın"
                      maxlength="150"
                      v-model="detail.tr"
                    ></textarea>
                  </div>
                  
                  <div class="separator separator-dashed my-10"></div>

                  <div class="form-group">
                    <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                      Olanak
                      <Flag class="ml-2" code="GB-UKM" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                    </label>
                    <input type="text" class="form-control form-control-lg" placeholder="Olanağı isimlendirin" v-model="title.en"/>
                  </div>

                  <div class="form-group">
                    <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
                      Açıklama (İsteğe Bağlı)
                      <Flag class="ml-2" code="GB-UKM" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/>
                    </label>
                    <textarea
                      class="form-control mb-2"
                      rows="3"
                      placeholder="Açıklamayı buraya yazın"
                      maxlength="150"
                      v-model="detail.en"
                    ></textarea>
                  </div>
                </div>

                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                  <h1 class="mb-5 font-weight-bolder text-dark display-4">
                    İkon ekle
                  </h1>
                  <p class="font-size-h5 mb-7">
                    Olanağı en iyi şekilde anlatan bir ikon yükleyin.
                  </p>

                  <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="!validation.step2">
                    <div class="alert-text text-body d-flex align-items-center">
                      <span class="svg-icon svg-icon-warning pr-2">
                        <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
                      </span>
                      <span class="font-weight-bold">Devam edebilmek için lütfen bir ikon yükleyin.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <file-pond
                      name="icon"
                      allow-multiple="false" 
                      accepted-file-types="image/*"
                      max-files="1" 
                      maxFileSize="20KB"
                      :file-rename-function="renameFile" 
                      v-on:removefile="removeFile"
                      :server="{ process }"
                      label-idle='<div style="width:100%;height:100%;">
                        <h3 style="font-size: 14px">
                          İkonu Sürükle &amp; Bırak veya <span class="filepond--label-action" tabindex="0">Gözat</span><br>
                        </h3>
                      </div>'
                    />
                    <input type="hidden" name="provideIcon" v-model="provideIcon">
                  </div>
                </div>

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      class="btn btn-secondary font-weight-bold px-9 py-4"
                      data-wizard-type="action-prev"
                    >
                      Geri
                    </button>
                  </div>
                  <div>
                    <button
                      @click="submit"
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      :class="{ 'spinner spinner-white spinner-left mr-3': isSubmitting }"
                      ref="kt_create_provide"
                      data-wizard-type="action-submit"
                      :disabled="isSubmitting"
                    >
                      Olanağı Oluştur
                    </button>
                    <button
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      data-wizard-type="action-next"
                    >
                      Sonraki Adım
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->

              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import KTWizard from "@/assets/js/components/wizard";
import JwtService from '@/core/services/jwt.service';

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm.js';
import FilePondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm.js';
import 'filepond/dist/filepond.min.css';
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginFileRename);
export default {
  components: {
    FilePond
  },
  data() {
    return {
      wizard: null,
      steps: [
        {
          title: 'Olanak',
          state: 'current'
        },
        {
          title: 'İkon',
          state: 'pending'
        },
      ],

      // Step 1
      title: {
        tr: '',
        en: ''
      },
      detail: {
        tr: '',
        en: ''
      },

      // Step 2
      provideIcon: null,

      validation: {
        step1: true,
        step2: true
      },
      isSubmitting: false
    }
  },
  mounted() {
    const self = this;
    // Initialize form wizard
    this.wizard = new KTWizard("kt_wizard_provide", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    this.wizard.on("change", function(wizardObj) {
      if (wizardObj.newStep < wizardObj.currentStep) {
        return;
      }

      const step = wizardObj.getStep();
      switch (step) {
        case 1:
          if (self.title.tr.length === 0 || self.title.en.length === 0) {
            self.validation.step1 = false;
            wizardObj.stop();
          } else { self.validation.step1 = true; }
          break;
        default:
          break;
      }
    });
  },
  methods: {
    renameFile(file) {
      return Date.now() + '_' + file.name;
    },

    removeFile() {
      this.provideIcon = null;
    },

    process (fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData();
      formData.append('image', file, file.name);

      const request = new XMLHttpRequest();
      request.open("POST", "https://api.zenofontours.com/admin/v1/provide/icon/upload.req.php");
      request.setRequestHeader('Authorization', JwtService.getToken());

      request.upload.onprogress = e => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      const self = this;
      request.onload = function() {
        if (request.status >= 200 && request.status < 300) {
          self.provideIcon = file.name;
          load();
        } else {
          // Can call the error method if something is wrong, should exit after
          self.provideIcon = null;
          error();
        }
      };

      request.send(formData);

      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          abort();
        }
      }; 
    },

    submit() {
      if (this.provideIcon === null) {
        this.validation.step2 = false;
        return;
      }
      this.validation.step2 = true;

      this.isSubmitting = true;
      const postData = {
        title: JSON.stringify(this.title),
        detail: JSON.stringify(this.detail),
        icon: this.provideIcon
      }
      
      ApiService.post('provide/add.req.php', postData)
      .then(() => {
        setTimeout(() => {
          this.isSubmitting = false;
          this.$router.push({ name: "provides" });  
        }, 1000);
      })
      .catch(({ response }) => {
        setTimeout(() => {
          this.isSubmitting = false;
          alert(response.data.errorMessage);
        }, 1000);
      });

    },
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step {
  border: 2px solid transparent;
  border-radius: 0.85rem;
}
.wizard.wizard-2
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='current']:after {
  display: none;
}
</style>