<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-between mb-8">
      <h2 class="font-weight-bold my-2 mr-5">
        Olanak Oluştur
      </h2>
      <div class="d-flex flex-row align-items-center">
      <router-link
        :to="{ name: 'provides'}"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5">
          <span class="d-none d-md-inline">Olanaklar</span>
        </a>
      </router-link>
      </div>
    </div>
    <ProvideWizard />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProvideWizard from "@/view/pages/provide/components/ProvideWizard";

export default {
  name: "CreateProvide",
  components: {
    ProvideWizard
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "CreateProvide" }]);
  },
}
</script>

<style>

</style>